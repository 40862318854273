.card {
    width: 150px;
    height: 200px;
    margin: 15px;
    transition: transform 0.3s ease-in-out;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0,0,0,0.15);
  }
  
  .card-img-top {
    height: auto;
    object-fit: cover;
    margin-bottom: 5px;
    border-radius: 5px;
  }
  
  .card-body {
    padding: 5px;
  }
  
  .card-title {
    margin-bottom: 0;
    font-size: 14px;
  }
  
  .card-title a {
    color: #333;
    text-decoration: none;
    font-weight: bold;
  }
  
  .card-title a:hover {
    color: #007bff;
  }
  
  .card-title p {
    margin: 0;
    overflow: hidden;
    /* text-overflow: ellipsis; */
    white-space: nowrap;
    width: 100%;
    font-weight: lighter;
    transition: transform 0.5s ease; 
    display: inline-block;
    position: relative;
  }
