/* MusicPlayer.css */
.music-player {
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 20px;
    position: sticky;
    bottom: 0;
    width: 100%;
    z-index: 1000; /* Adjust z-index if necessary */
    background-color: #fff; /* Background color to match the page */
    box-shadow: 0 -2px 5px rgba(0,0,0,0.1); /* Optional: add shadow for better visibility */
}
.premium-message {
    background-color: #ffd700;
    color: #333;
    padding: 10px;
    text-align: center;
    font-weight: bold;
    margin-top: 10px;
    border-radius: 5px;
}
.player-content {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.song-info {
    display: flex;
    align-items: center;
}

.cover-art {
    width: 100px;
    height: 100px;
    border-radius: 5px;
    margin-right: 20px;
}

.song-details {
    flex-grow: 1;
}

.song-title {
    font-size: 1.5em;
    margin: 0;
    color: #333;
}

.artist {
    font-size: 1em;
    color: #666;
    margin: 5px 0 0;
}

.controls {
    display: flex;
    align-items: center;
}

.play-pause {
    background-color: #1db954;
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 1.2em;
    cursor: pointer;
    margin-right: 20px;
}

.time {
    font-size: 0.9em;
    color: #666;
}

.progress-container {
    width: 100%;
    margin-bottom: 10px;
}

.progress-bar {
    background-color: #ddd;
    height: 5px;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    width: 100%;
    margin: 0 20px;
}

.progress {
    background-color: #1db954;
    height: 100%;
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: 0;
}

.volume-control {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-left: 20px;
}

.volume-control button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2em;
    color: #666;
    margin-right: 10px;
}

.volume-control input[type="range"] {
    width: 100px;
}

.quality {
    font-size: 0.8em;
    color: #999;
    text-align: right;
    margin-top: 10px;
}
