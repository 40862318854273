.row.footer{
    margin-top: 10px;
    background-color: antiquewhite;
    width: 100%;
}
.icon {
    font-size: 2rem;
    margin: 0.5rem;
    text-align: center;

}
.col-md-2{
    padding-top: 10px;
    text-align: left;
}
.col-md-5{
    padding-top: 10px;
    text-align: center;
}
a {
    
   
    text-decoration: none;
    color: gray;
}