.carousel-image {
    width: 100%;
    height: auto;
    max-height: 500px; 
    object-fit: cover; 
  }
  /* Header.css */

.navbar {
  padding: 10px 0;
}

.navbar-brand img {
  width: 20%;
  margin-right: 5px;
}

.nav-link {
  margin-right: 15px;
}

.search-form {
  margin-right: 10px;
}

.upgrade-button {
  margin-right: 10px;
}

.user-dropdown {
  margin-left: 10px;
}

@media (max-width: 991px) {
  .navbar-nav,
  .form-inline,
  .navbar-text {
    margin-top: 10px;
  }
}