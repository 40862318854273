.form-container {
  padding: 20px;
  margin: 0 auto;
  max-width: 700px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.form-title {
  text-align: center;
  font-size: 2em;
  margin-bottom: 20px;
}

.form-label {
  font-weight: bold;
  margin-bottom: 5px;
}

.form-control {
  height: 38px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* You can add more styles here for specific elements */
