/* Album.css */
.album-card {
    width: 100%;
    height: auto; /* Set a fixed height for the cards */
    overflow: hidden;
  }
  
  .album-card img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the card */
  }
  
  .album-card-title {
    text-align: center;
    font-size: 1rem;
  }
  