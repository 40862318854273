
.song-info {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.cover-art {
    width: 60px;
    height: 60px;
    border-radius: 5px;
    margin-right: 15px;
}

.song-details {
    flex-grow: 1;
}

.song-title {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
}

.artist {
    margin: 5px 0 0;
    font-size: 14px;
    color: #6c757d;
}

/* .lyrics {
    text-align: center;
    font-style: italic;
    margin: 10px 0;
    height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: opacity 0.5s ease-in-out;
} */

.album-card {
    transition: transform 0.3s ease-in-out;
}

.album-card:hover {
    transform: scale(1.05);
}

.album-card-img {
    height: 150px;
    object-fit: cover;
}

.album-card-title {
    font-size: 14px;
    text-align: center;
}
.play-icon {
    color: #007bff; /* Bootstrap primary color */
    font-size: 1.5rem;
    transition: transform 0.2s;
}

.play-icon:hover {
    transform: scale(1.2);
    color: #0056b3; /* Darker shade of the primary color */
}

.index-color-1 { color: #007bff; }
.index-color-2 { color: #28a745; }
.index-color-3 { color: #dc3545; }
.index-color-4 { color: #ffc107; }
.index-color-5 { color: #17a2b8; }